<template>
  <div>
    <div id="BRANDED">
      <div
        class="branded-div"
        :style="{
          'background-image': `url(${
            isMiniWindows ? imgs2[0].value : imgs[0].value
          })`,
        }"
      >
        <div
          v-for="(item, index) in zzry"
          :key="index"
          class="this_only_pc branded-subdiv-1"
          :class="index == zzry.length - 1 ? 'branded-subdiv-6' : ''"
        >
          <img class="branded-subdiv-icon" :src="item.img" />
          <p>{{ $i18n.locale == "zh" ? item.title : item.titleEn }}</p>
          <p
            class="little"
            v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
          ></p>
        </div>
        <el-row class="only_phone">
          <el-col
            :xs="12"
            :sm="12"
            :md="12"
            :lg="4"
            v-for="(item, index) in zzry"
            :key="index"
          >
            <div class="branded-subdiv-2">
              <img class="branded-subdiv-icon" :src="item.img" />
              <p>{{ $i18n.locale == "zh" ? item.title : item.titleEn }}</p>
              <p
                class="little"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      zzry: [],
      imgs: [],
      imgs2: [],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  created() {},
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
  },
  computed: {},
  mounted() {
    this.getList();
    this.getImg();
    this.getImg2();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  destroyed() {},
  methods: {
    getImg() {
      var p = { key: "优势背景图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getImg2() {
      var p = { key: "移动端优势背景图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs2 = res.list;
          }
        })
        .catch(() => {});
    },
    getList() {
      API.getAboutCompany()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.zzry = res.page.list;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.this_only_pc {
  display: flex;
}
/deep/.el-row {
  width: 100%;
}
/deep/.el-col {
  height: 100%;
}
.branded-div {
  // background-image: url("../../../static/img/about_branded_bg.png");
  display: flex;
  margin-top: 12rem;
  margin-bottom: 17rem;
  height: 80rem;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-around;
  background-position: center;
  border-radius: 4rem;
  .branded-subdiv {
    height: 100%;
  }
  .branded-subdiv-1 {
    @extend .branded-subdiv;
    box-sizing: border-box;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 5rem;
    transition: all 0.5s;
    border-radius: 0;

    &:first-child {
      border-radius: 4rem 0 0 4rem;
    }

    .branded-subdiv-icon {
      transition: all 0.5s;
      z-index: 2;
      width: 5rem;
      height: 5rem;
      background-size: contain;
      background-repeat: no-repeat;
      // background-image: url("../../../static/img/about_branded_icon_1.png");
      transform: translateY(30rem);
    }
    &:hover {
      background: $color17-45;
      backdrop-filter: saturate(180%) blur(20px);
      .little {
        opacity: 1;
      }
      .branded-subdiv-icon {
        transform: none;
      }
      p {
        transform: none;
      }
    }
  }
  .branded-subdiv-2 {
    @extend .branded-subdiv-1;
    border-radius: 0;
    .branded-subdiv-icon {
      z-index: 2;
    }
  }
  .branded-subdiv-3 {
    @extend .branded-subdiv-2;
    .branded-subdiv-icon {
      z-index: 2;
    }
  }
  .branded-subdiv-4 {
    @extend .branded-subdiv-2;
    background: red;
    .branded-subdiv-icon {
      z-index: 2;
    }
  }
  .branded-subdiv-5 {
    @extend .branded-subdiv-2;
    .branded-subdiv-icon {
      z-index: 2;
    }
  }
  .branded-subdiv-6 {
    @extend .branded-subdiv-1;
    border-radius: 0 4rem 4rem 0;
    .branded-subdiv-icon {
      z-index: 2;
    }
  }

  p {
    z-index: 2;
    color: white;
    font-size: 2.5rem;
    text-align: center;
    font-family: demibold;
    margin-top: 3rem;
    transform: translateY(30rem);
    transition: all 0.5s;
  }
  .little {
    opacity: 0;
    margin-top: 2rem;
    font-size: 1.6rem;
    font-family: normal;
    line-height: 2.4rem;
  }
}

@media screen and (max-width: 1200px) {
  .this_only_pc {
    display: none !important;
  }
  /deep/.el-row {
    padding: 5rem 0;
  }
  /deep/.el-col {
    height: 33%;
    &:nth-child(2n-1) {
      border-right: $textWhiteColor20 solid 1px;
    }
  }
  .branded-div {
    height: 90rem;
    border-radius: 2rem;
    margin-top: 5rem;
    margin-bottom: 10rem;
    .branded-subdiv {
      border-radius: 0;

      &:hover {
        background: none;
        backdrop-filter: none;
      }
      .little {
        display: none;
      }
      padding: 0;
      .branded-subdiv-icon {
        transform: none;
      }
      p {
        transform: none;
        font-size: 2.8rem;
      }
    }
  }
}
</style>