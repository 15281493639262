<template>
  <div>
    <img class="page_top_img top_img" :src="imgs[0].value" />
    <div class="empty_div2">
      <arc-bg />
      <div class="home_div title_padding">
        <p class="home_title">{{ $t("msg.新闻资讯") }}</p>

        <div class="top_news_div" @click="pushTo('/NewsDetail/id=' + list[0].id)">
          <img :src="list[0].cover" />
          <div class="top_news_div_right">
            <p class="top_news_title">
              {{ $i18n.locale == "zh" ? list[0].title : list[0].titleEn }}
            </p>
            <p class="top_news_content">
              {{ $i18n.locale == "zh" ? list[0].simple : list[0].simpleEn }}
            </p>
            <p class="top_news_time">
              <span class="top_news_type">{{
                $i18n.locale == "zh" ? list[0].type : list[0].typeEn
              }}</span>
              |
              {{ list[0].activityTime }}
            </p>
            <p
              class="news_more_touch_box"
              @click="pushTo('/NewsDetail/id=' + list[0].id)"
            >
              {{ $t("msg.详细内容") }}
            </p>
          </div>
        </div>
        <el-row class="sub_news_div">
          <el-col :md="8" v-for="(item, i) in list.slice(1, list.length)" :key="i">
            <div
              class="news_sub_box"
              @click="pushTo('/NewsDetail/id=' + item.id)"
            >
              <div class="top_div">
                <img :src="item.cover" />
              </div>
              <div class="bottom_content">
                <p class="news_sub_title">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
                <p class="news_sub_content">
                  {{ $i18n.locale == "zh" ? item.simple : item.simpleEn }}
                </p>
                <p class="news_sub_time">
                  <span class="news_sub_type">{{
                    $i18n.locale == "zh" ? item.type : item.typeEn
                  }}</span>
                  |
                  {{ item.activityTime }}
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  <script>
import ArcBg from "./view/ArcBg.vue";
import * as API from "@/api/index";
export default {
  components: { ArcBg },
  data() {
    return {
      list: "",
      imgs: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("/News") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getList();
    this.getImg();
  },
  methods: {
    getImg() {
      var p = { key: "新闻资讯顶部图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
    getList() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
  <style lang="scss" scoped>
.top_news_div {
  margin-top: 7rem;
  background: $colorF5;
  display: flex;
  border-radius: 4rem;
  img {
    cursor: pointer;
    border-radius: 4rem 0 0 4rem;
    width: 50%;
    max-height: 46rem;
    object-fit: cover;
    background: white;
  }
  .top_news_div_right {
    cursor: pointer;
    width: 50%;
    padding: 5.8rem 11.2rem 4rem 6.6rem;
    display: flex;
    flex-direction: column;

    .top_news_title {
      font-size: 2.4rem;
      color: $textBlueColor;
      font-family: demibold;
      line-height: 4rem;
      text-align: start;
    }
    .top_news_content {
      margin: 2.5rem 0 3.5rem;
      text-align: start;
      font-size: 1.8rem;
      font-family: normal;
      line-height: 2.4rem;
    }
    .top_news_type {
      text-align: start;
      font-size: 1.8rem;
      font-family: light;
      color: $textBlueColor;
    }
    .top_news_time {
      text-align: start;
      font-size: 1.8rem;
      font-family: light;
    }
    .news_more_touch_box {
      margin-top: auto;
      font-size: 1.8rem;
      border: $textGaryColor solid 1px;
      border-radius: 2rem;
      padding: 1rem 4rem;
      width: fit-content;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        transition: all 0.5s;
        border: $textBlueColor solid 1px;
        background-color: $textBlueColor;
        color: white;
      }
    }
    &:hover {
      .news_more_touch_box {
        border: $textBlueColor solid 1px;
        background-color: $textBlueColor;
        color: white;
      }
    }
  }
}
.sub_news_div {
  margin-top: 12rem;
}
.news_sub_box {
  cursor: pointer;
  background-color: transparent;
  border-radius: 3rem;
  width: 52rem;

  .top_div {
    border-radius: 3rem;
    padding: 2rem 1.7rem;
    transition: all 0.5s;
    background-color: $colorF5;
    &:hover {
      background-color: $textBlueColor;
    }
  }
  img {
    width: 48rem;
    height: 42rem;
    object-fit: cover;
    border-radius: 3rem;
  }

  .bottom_content {
    margin: 1.8rem 2rem;
    display: flex;
    flex-direction: column;

    p {
      text-align: start;
    }

    .news_sub_title {
      font-size: 2.2rem;
      line-height: 3.8rem;
      font-family: demibold;
      color: $textBlueColor;
      margin: 1rem 0 4rem;
    }
    .news_sub_content {
      font-size: 1.8rem;
      font-family: normal;
      margin-bottom: 3rem;
      line-height: 2.2rem;
    }
    .news_sub_type {
      font-size: 1.8rem;
      font-family: light;
      color: $textBlueColor;
    }
    .news_sub_time {
      font-size: 1.8rem;
      font-family: light;
    }
  }
}

@media (max-width: 1200px) {
  .top_news_div {
    display: none;
  }
  .sub_news_div {
    margin-top: 0;
  }
  .news_sub_box {
    margin-top: 5rem;
    width: 100%;
    .top_div {
      padding: 1rem;
    }
    img {
      height: 32rem;
      width: 100%;
    }
    .bottom_content {
      .news_sub_title {
        font-size: 3rem;
      }
      .news_sub_content {
        font-size: 2.2rem;
        line-height: 1.5;
      }
      .news_sub_time {
        font-size: 2rem;
      }
      .news_sub_type {
        font-size: 2rem;
      }
    }
  }
}
</style>