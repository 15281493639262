<template>
  <div>
    <img class="page_top_img top_img" :src="imgs[0].value" />
    <div class="empty_div2">
      <arc-bg />
      <div class="home_div title_padding">
        <p class="home_title">{{ $t("msg.联系我们") }}</p>
        <div class="call_div">
          <div class="call_bg">
            <div class="call_bg_cirl"></div>
          </div>
          <div class="call_left">
            <p class="call_title">
              {{ $i18n.locale == "zh" ? data.name : data.nameEn }}
            </p>
            <div class="call_con_div">
              <img src="../../static/img/call_addr_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.地址") }}</p>
                <p class="call_content">
                  {{ $i18n.locale == "zh" ? data.address : data.addressEn }}
                </p>
              </div>
            </div>
            <div class="call_con_div">
              <img src="../../static/img/call_phone_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.电话") }}</p>
                <p class="call_content" v-html="data.otherTel"></p>
              </div>
            </div>
            <div class="call_con_div">
              <img src="../../static/img/call_tel_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.内销部") }}</p>
                <p class="call_content" v-html="data.tel"></p>
              </div>
            </div>
            <div class="call_con_div">
              <img src="../../static/img/call_pr_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.传真") }}</p>
                <p class="call_content" v-html="data.otherTel2"></p>
              </div>
            </div>
            <div class="call_con_div">
              <img src="../../static/img/call_email_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.邮箱") }}</p>
                <p class="call_content">{{ data.email }}</p>
              </div>
            </div>
            <div class="call_con_div">
              <img src="../../static/img/web_icon.png" />
              <div class="call_con_sub">
                <p class="call_addr">{{ $t("msg.网址") }}</p>
                <p class="call_content" v-html="data.otherEmail"></p>
              </div>
            </div>
          </div>
          <div class="call_right">
            <baidu-map
              :center="center"
              :zoom="zoom"
              @ready="handler"
              class="bm-view"
            >
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArcBg from "./view/ArcBg.vue";
import * as API from "@/api/index";
export default {
  components: { ArcBg },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 14,
      data: {},
      imgs: [],
      lng: 121.467072,
      lat: 29.347974,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("/CallUs") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    this.getData();
  },
  methods: {
    getImg() {
      var p = { key: "联系我们顶部图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      var that = this;
      this.center.lng = this.lng;
      this.center.lat = this.lat;
      var point = new BMap.Point(this.lng, this.lat);
      map.enableScrollWheelZoom(true);
      map.centerAndZoom(point, that.zoom);
      map.addEventListener("zoomend", function () {
        // alert("地图缩放至：" + this.getZoom() + "级");
        map.panTo(new BMap.Point(that.lng, that.lat));
      });
      window.setTimeout(function () {
        map.panTo(new BMap.Point(that.lng, that.lat));
      }, 2000);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
    },
    getData() {
      API.getContacts()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.data = res.data[0];
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.call_div {
  margin-top: 9rem;
  display: flex;
  //   padding-left: 9rem;
  //   padding-top: 6.2rem;
  //   padding-bottom: 6.2rem;
  border-radius: 4rem;
  justify-content: space-between;
  position: relative;
  .call_bg {
    z-index: 0;
    position: absolute;
    width: 95%;
    height: 100%;
    background: $textBlueColor;
    border-radius: 4rem;
    overflow: hidden;
    .call_bg_cirl {
      width: 55.6rem;
      height: 55.6rem;
      border-radius: 50%;
      background: linear-gradient(
        37deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.5;
      bottom: -32rem;
      left: 40rem;
      position: absolute;
      &:after {
        content: "";
        position: relative;
        top: 8rem;
        left: 8rem;
        display: block;
        width: 39.6rem;
        height: 39.6rem;
        border-radius: 50%;
        background-color: $textBlueColor;
      }
    }
  }
  .call_left {
    margin-top: 6.2rem;
    margin-left: 9rem;
    margin-bottom: 6.2rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .call_title {
      margin-bottom: 5rem;
      text-align: start;
      font-family: demibold;
      font-size: 3.6rem;
      color: white;
    }
    .call_con_div {
      margin-top: 3rem;
      display: flex;
      img {
        width: 4.6rem;
        height: 4.6rem;
      }
      .call_con_sub {
        margin-left: 1.8rem;
        margin-top: 1rem;
        p {
          color: $colorFB;
          text-align: start;
        }
        .call_addr {
          font-family: demibold;
          font-size: 2.6rem;
        }
        .call_content {
          font-size: 2.4rem;
        }
      }
    }
  }
  .call_right {
    margin-top: 6.2rem;
    margin-bottom: 6.2rem;
    height: 88rem;
    width: 94rem;
    .bm-view {
      width: 100%;
      height: 100%;
      /deep/ div {
        border-radius: 4rem !important;
      }
      // height: 400px;
    }
  }
}
@media (max-width: 1200px) {
  .call_div {
    flex-direction: column;
    .call_bg {
      width: 100%;
      height: 60%;
      overflow: hidden;
      .call_bg_cirl {
        width: 35.6rem;
        height: 35.6rem;
        bottom: -18rem;
        left: 35%;
        &:after {
          top: 5rem;
          left: 5rem;
          width: 25.6rem;
          height: 25.6rem;
        }
      }
    }
    .call_left {
      margin-left: 5rem;
      .call_con_div .call_con_sub {
        margin-top: 0;
      }
      .call_title {
        margin-bottom: 2rem;
      }
    }
    .call_right {
      width: 100%;
      height: 80vw;
    }
  }
}
</style>