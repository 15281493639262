<template>
  <div>
    <div>
      <div class="top_view top_img2">
        <img
          class="page_top_img"
          :src="isMiniWindows ? Detail.picSizeEn : Detail.picSize"
        />
        <div class="top_title_div">
          <p class="tag">
            {{
              $i18n.locale == "zh"
                ? Detail.typeName.slice(
                    Detail.typeName.indexOf("/") + 1,
                    Detail.typeName.length
                  )
                : Detail.typeNameEn.slice(
                    Detail.typeNameEn.indexOf("/") + 1,
                    Detail.typeNameEn.length
                  )
            }}
          </p>
          <p
            class="title"
            :class="
              !Detail.detail1[0] &&
              !Detail.detail2[0] &&
              !Detail.detail3[0] &&
              !Detail.detail4[0] &&
              !Detail.detail5[0] &&
              !Detail.detail6[0] &&
              !Detail.detail7[0] &&
              !Detail.detail8[0] &&
              !Detail.detail9[0] &&
              !Detail.symbol &&
              !Detail.pressure &&
              !Detail.flow
                ? 'gary'
                : ''
            "
          >
            {{ $i18n.locale == "zh" ? Detail.key : Detail.keyEn }}
          </p>
          <p
            class="content"
            :class="
              !Detail.detail1[0] &&
              !Detail.detail2[0] &&
              !Detail.detail3[0] &&
              !Detail.detail4[0] &&
              !Detail.detail5[0] &&
              !Detail.detail6[0] &&
              !Detail.detail7[0] &&
              !Detail.detail8[0] &&
              !Detail.detail9[0] &&
              !Detail.symbol &&
              !Detail.pressure &&
              !Detail.flow
                ? 'gary'
                : ''
            "
            v-html="
              $i18n.locale == 'zh' ? Detail.description : Detail.descriptionEn
            "
          ></p>
          <div class="top_video_div bottom_item" v-if="Detail.symbol">
            <img src="../../static/img/detail_icon_video1.png" />
            <p class="content2" @click="showVideo()">
              {{ $t("msg.观看产品视频") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="empty_div3"
        :class="
          !Detail.detail1[0] &&
          !Detail.detail2[0] &&
          !Detail.detail3[0] &&
          !Detail.detail4[0] &&
          !Detail.detail5[0] &&
          !Detail.detail6[0] &&
          !Detail.detail7[0] &&
          !Detail.detail8[0] &&
          !Detail.detail9[0] &&
          !Detail.symbol &&
          !Detail.pressure &&
          !Detail.flow
            ? 'padding_0'
            : ''
        "
      >
        <arc-bg
          v-if="
            Detail.detail1[0] ||
            Detail.detail2[0] ||
            Detail.detail3[0] ||
            Detail.detail4[0] ||
            Detail.detail5[0] ||
            Detail.detail6[0] ||
            Detail.detail7[0] ||
            Detail.detail8[0] ||
            Detail.detail9[0] ||
            Detail.symbol ||
            Detail.pressure ||
            Detail.flow
          "
        />
        <div class="p_div">
          <div
            class="p1_div"
            v-for="(item, index) in Detail.detail1"
            :key="'detail1' + index"
            :class="index > 0 ? 'reverse marginTop10' : ''"
          >
            <div class="p1_left">
              <p
                class="p1_title"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
              <p
                class="p1_content"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
              <div class="p1_bottom marginTop">
                <div class="bottom_div" v-if="item.icon1">
                  <img :src="item.icon1" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle1 : item.iconTitleEn1
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon2">
                  <img :src="item.icon2" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle2 : item.iconTitleEn2
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon3">
                  <img :src="item.icon3" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle3 : item.iconTitleEn3
                    "
                  ></p>
                </div>
              </div>
            </div>
            <div class="p1_right">
              <img :src="item.img2" />
            </div>
          </div>

          <div
            class="p2_div"
            v-for="(item, index) in Detail.detail2"
            :key="'detail2' + index"
          >
            <div class="p2_left">
              <img class="p2_left_top_img" :src="item.img1" />
              <p
                class="p1_title marginTop"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
              <p
                class="p1_content marginTop"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
            </div>
            <div class="p2_right">
              <img :src="item.img2" />
            </div>
          </div>
        </div>

        <div
          class="p3_div"
          v-if="Detail.detail3[0]"
          :style="{
            'background-image': `url(${
              Detail.detail3[0]
                ? isMiniWindows
                  ? Detail.detail3[0].img1
                  : Detail.detail3[0].img2
                : ''
            })`,
          }"
        >
          <div class="p_div">
            <div class="p3_left">
              <p
                class="p1_title marginTop white"
                v-html="
                  $i18n.locale == 'zh'
                    ? Detail.detail3[0].title
                    : Detail.detail3[0].titleEn
                "
              ></p>
              <p
                class="p3_content marginTop white"
                v-html="
                  $i18n.locale == 'zh'
                    ? Detail.detail3[0].content
                    : Detail.detail3[0].contentEn
                "
              ></p>
              <div
                class="p1_bottom marginTop"
                :class="Detail.detail3[0].icon3 ? '' : 'marginLeftDiv10'"
              >
                <div class="bottom_div" v-if="Detail.detail3[0].icon1">
                  <img :src="Detail.detail3[0].icon1" />
                  <p
                    class="bottom_txt white"
                    v-html="
                      $i18n.locale == 'zh'
                        ? Detail.detail3[0].iconTitle1
                        : Detail.detail3[0].iconTitleEn1
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="Detail.detail3[0].icon2">
                  <img :src="Detail.detail3[0].icon2" />
                  <p
                    class="bottom_txt white"
                    v-html="
                      $i18n.locale == 'zh'
                        ? Detail.detail3[0].iconTitle2
                        : Detail.detail3[0].iconTitleEn2
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="Detail.detail3[0].icon3">
                  <img :src="Detail.detail3[0].icon3" />
                  <p
                    class="bottom_txt white"
                    v-html="
                      $i18n.locale == 'zh'
                        ? Detail.detail3[0].iconTitle3
                        : Detail.detail3[0].iconTitleEn3
                    "
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p_div">
          <div
            class="p2_div"
            v-for="(item, index) in Detail.detail4"
            :key="'detail4' + index"
            :class="index == 0 || index == 3 ? 'reverse' : ''"
          >
            <div class="p2_left">
              <img class="p2_left_top_img" :src="item.img1" />
              <p
                class="p1_title marginTop"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
              <p
                class="p1_content marginTop"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
              <div class="p4_bottom">
                <div class="bottom_div" v-if="item.icon1">
                  <img :src="item.icon1" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle1 : item.iconTitleEn1
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon2">
                  <img :src="item.icon2" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle2 : item.iconTitleEn2
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon3">
                  <img :src="item.icon3" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle3 : item.iconTitleEn3
                    "
                  ></p>
                </div>
              </div>
            </div>
            <div class="p2_right">
              <img :src="item.img2" />
            </div>
          </div>
        </div>

        <div
          class="p4_div"
          v-if="Detail.detail5[0]"
          :style="{
            'background-image': `url(${
              Detail.detail5[0] ? Detail.detail5[0].img2 : ''
            })`,
          }"
        >
          <div class="p_div">
            <p
              class="p1_title marginTop"
              v-html="
                $i18n.locale == 'zh'
                  ? Detail.detail5[0].title
                  : Detail.detail5[0].titleEn
              "
            ></p>
            <p
              class="p3_content marginTop"
              v-html="
                $i18n.locale == 'zh'
                  ? Detail.detail5[0].content
                  : Detail.detail5[0].contentEn
              "
            ></p>
            <div class="p4_sub_div">
              <p
                class="p1_content"
                v-html="
                  $i18n.locale == 'zh'
                    ? Detail.detail5[0].iconTitle1
                    : Detail.detail5[0].iconTitleEn1
                "
              ></p>
              <img :src="Detail.detail5[0].img1" />
            </div>
          </div>
        </div>
        <div class="p_div">
          <p
            class="p1_title marginTop"
            v-html="
              Detail.detail6[0]
                ? $i18n.locale == 'zh'
                  ? Detail.detail6[0].title
                  : Detail.detail6[0].titleEn
                : ''
            "
          ></p>
          <div class="p5_div" v-if="Detail.detail6[0]">
            <div class="p5_sub_div">
              <img :src="Detail.detail6[0] ? Detail.detail6[0].icon1 : ''" />
              <p
                class="p1_content"
                v-html="
                  Detail.detail6[0]
                    ? $i18n.locale == 'zh'
                      ? Detail.detail6[0].iconTitle1
                      : Detail.detail6[0].iconTitle1En
                    : ''
                "
              ></p>
            </div>
            <div class="p5_sub_div">
              <img :src="Detail.detail6[0] ? Detail.detail6[0].icon2 : ''" />
              <p
                class="p1_content"
                v-html="
                  Detail.detail6[0]
                    ? $i18n.locale == 'zh'
                      ? Detail.detail6[0].iconTitle2
                      : Detail.detail6[0].iconTitle2En
                    : ''
                "
              ></p>
            </div>
          </div>
          <div
            class="p2_div"
            v-for="(item, index) in Detail.detail7"
            :key="'detail7' + index"
            :class="index % 2 == 0 ? 'reverse' : ''"
          >
            <div class="p2_left">
              <img class="p2_left_top_img" :src="item.img1" />
              <p
                class="p1_title marginTop"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
              <p
                class="p1_content marginTop"
                v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
              ></p>
              <div class="p4_bottom">
                <div class="bottom_div" v-if="item.icon1">
                  <img :src="item.icon1" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle1 : item.iconTitleEn1
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon2">
                  <img :src="item.icon2" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle2 : item.iconTitleEn2
                    "
                  ></p>
                </div>
                <div class="bottom_div" v-if="item.icon3">
                  <img :src="item.icon3" />
                  <p
                    class="bottom_txt"
                    v-html="
                      $i18n.locale == 'zh' ? item.iconTitle3 : item.iconTitleEn3
                    "
                  ></p>
                </div>
              </div>
            </div>
            <div class="p2_right">
              <img :src="item.img2" />
            </div>
          </div>
        </div>
        <div class="p_div">
          <p class="home_title top_margin_18" v-if="Detail.detail8[0]">
            {{ $t("msg.产品细节") }}
          </p>
          <el-row>
            <el-col
              :xs="12"
              :md="12"
              v-for="(item, i) in Detail.detail8"
              :key="'detail8' + i"
            >
              <div class="p6_div">
                <img :src="item.img2" />
                <p>{{ $i18n.locale == "zh" ? item.title : item.titleEn }}</p>
              </div>
            </el-col>
          </el-row>
          <p
            class="home_title top_margin_18 bottom_margin_5"
            v-if="Detail.detail9[0]"
          >
            {{ $t("msg.产品参数") }}
          </p>
          <el-row type="flex" style="flex-wrap: wrap">
            <el-col
              :xs="12"
              :sm="12"
              :md="12"
              :lg="6"
              v-for="(item, i) in Detail.detail9"
              :key="'detail9' + i"
              class="p7_col"
            >
              <div class="p7_div" :class="i % 2 != 0 ? 'middle' : ''">
                <p class="p7_title">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
                <p
                  class="p7_content"
                  v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
                ></p>
              </div>
            </el-col>
          </el-row>

          <p
            class="home_title top_margin_18 bottom_margin_5"
            v-if="Detail.flow && $i18n.locale != 'zh'"
          >
            {{ $t("msg.装箱清单") }}
          </p>

          <div class="p9_div" v-if="Detail.flow && $i18n.locale != 'zh'">
            <p>{{ Detail.flow }}</p>
          </div>

          <p
            class="home_title top_margin_18 bottom_margin_5"
            v-if="Detail.symbol"
          >
            {{ $t("msg.产品视频") }}
          </p>
          <div class="p8_div" v-if="Detail.symbol" id="product_video">
            <!-- <video
          muted="muted"
          controls
          controlsList="nodownload"
          :src="Detail.symbol"
          width="100%"
          webkit-playsinline
          playsinline
          x5-video-player-type="h5"
          :poster="Detail.cavity"
        ></video> -->
            <video
              id="detail-video"
              muted="muted"
              controls
              controlsList="nodownload"
              :src="Detail.symbol"
              width="100%"
              webkit-playsinline
              playsinline
              x5-video-player-type="h5"
              :poster="Detail.cavity"
            ></video>
          </div>
          <p
            class="home_title top_margin_18 bottom_margin_5"
            v-if="Detail.pressure && $i18n.locale != 'zh'"
          >
            {{ $t("msg.文档说明书") }}
          </p>
          <div class="p9_div" v-if="Detail.pressure && $i18n.locale != 'zh'">
            <img src="../../static/img/detail_icon_download.png" />
            <p>{{ Detail.pressure }}</p>
            <p class="download" @click="toDownload(Detail.model)">
              {{ $t("msg.下载") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('msg.产品视频')" :visible.sync="dialogVideoVisible">
      <video
        id="detail-video"
        muted="muted"
        controls
        controlsList="nodownload"
        :src="Detail.symbol"
        width="100%"
        webkit-playsinline
        playsinline
        x5-video-player-type="h5"
        :poster="Detail.cavity"
      ></video>
    </el-dialog>
  </div>
</template>
  <script>
import ArcBg from "./view/ArcBg.vue";
import * as API from "@/api/index";
export default {
  components: { ArcBg },
  data() {
    return {
      Detail: {},
      dialogVideoVisible: false,
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/ProductsDetail") != -1) {
        window.scrollTo(0, 0);
        this.getDetail();
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getDetail();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getDetail() {
      var id = window.location.href.split("id=")[1];
      // id = 227;
      API.getProductDetail(id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.data;
            for (var i = 1; i < 10; i++) {
              // console.log("detail==" + i, this.Detail["detail" + i]);
              var detail = JSON.parse(this.Detail["detail" + i]);
              this.Detail["detail" + i] = detail;
            }
          }
        })
        .catch(() => {});
    },
    toDownload(url) {
      window.open(url, "_blank");
    },
    scrollIntoView(id) {
      document.getElementById(id).scrollIntoView(false);
    },
    showVideo() {
      this.dialogVideoVisible = !this.dialogVideoVisible;
    },
  },
};
</script>
  <style lang="scss" scoped>
.page_top_img {
  object-position: center;
}
.top_video_div {
  cursor: pointer;
  display: flex;
  img {
    width: 3rem;
    height: 3rem !important;
    margin-right: 1rem;
  }
}
.p_div {
  margin: 0 auto;
  max-width: 130rem;
  &.top_margin {
    margin-top: 10rem !important;
  }

  &.bottom_margin {
    margin-bottom: 10rem !important;
  }

  &.title_padding {
    width: 85%;
    margin: auto;
  }

  &.content_padding {
    width: 88%;
    margin: auto;
  }
}
.p1_div {
  display: flex;
  justify-content: space-between;
  &.reverse {
    flex-direction: row-reverse;
  }
  &.marginTop10 {
    margin-top: 10rem;
  }
}
.p1_title {
  font-family: demibold;
  font-size: 5rem;
  color: $textBlueColor;
  text-align: start;
  &.marginTop {
    margin-top: 1.2rem;
  }
  &.white {
    color: white;
  }
}
.p1_content {
  font-family: normal !important;
  font-size: 2.4rem !important;
  text-align: start;
  /deep/p {
    font-family: normal !important;
    font-size: 2.4rem !important;
    text-align: start;
  }
  &.marginTop {
    margin-top: 2.5rem;
  }
  &.white {
    color: white;
  }
}
.p3_content {
  @extend .p1_content;
  font-size: 3rem;
  &.marginTop {
    margin-top: 5rem;
  }
}
.p1_bottom {
  display: flex;
  justify-content: space-between;
  &.marginTop {
    margin-top: 5rem;
  }
  .bottom_div {
    // &:not(:first-child){
    //   margin-left:4rem;
    // }
    img {
      width: 6.7rem;
      height: 6.7rem;
    }
    .bottom_txt {
      font-size: 2.4rem;
      margin-top: 1.7rem;
      &.white {
        color: white;
      }
    }
  }
  &.only2 {
    justify-content: start;
  }
  &.marginLeftDiv5 {
    justify-content: start;
    div:not(:first-child) {
      margin-left: 5rem;
    }
  }
  &.marginLeftDiv10 {
    justify-content: start;
    div:not(:first-child) {
      margin-left: 10rem;
    }
  }
}
.p4_bottom {
  @extend .p1_bottom;
  margin-top: 5rem;
}
.p2_left_top_img {
  width: 7.1rem;
  height: 7.1rem;
}
.p1_left {
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.p1_right {
  width: 50%;
  img {
    width: 100%;
    height: auto;
  }
}
.p2_div {
  @extend.p1_div;
  margin-top: 20rem;
}
.p2_left {
  @extend .p1_left;
  width: 40%;
  justify-content: flex-start;
}
.p2_right {
  width: 50%;
  display: flex;
  min-height: 38rem;
  // background: red;
  align-items: end;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
.p3_div {
  height: 98rem;
  margin: 10rem 0;
  // background: $textGaryColor;
  padding: 34rem 0 0;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.p3_left {
  @extend .p1_left;
  width: fit-content;
}
.p4_div {
  padding: 7rem 0 3rem;
  margin: 10rem 0;
  min-height: auto;
  box-sizing: border-box;
  background: $colorB1-40;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .p4_sub_div {
    background: white;
    padding: 3rem 4rem;
    margin-top: 3.7rem;
    img {
      width: 100%;
      margin-top: 3rem;
    }
  }
}
.p5_div {
  display: flex;
  margin-bottom: 27rem;
  .p5_sub_div {
    width: 50%;
    display: flex;
    flex-direction: column;
    p {
      background: $colorF5;
      padding: 3rem 1rem;
      height: calc(100% - 47rem);
      box-sizing: border-box;
    }
    img {
      width: 100%;
      height: 47rem;
    }
    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
}
.p6_div {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 5rem;
  img {
    width: 100%;
    height: 55.7rem;
    display: block;
  }
  p {
    color: white;
    font-family: demibold;
    padding: 3rem;
    font-size: 3.5rem;
    background: $textBlueColor;
  }
}
.p7_col {
  &:not(:nth-child(4n + 1)) {
    padding-left: 9rem;
    padding-right: 1rem;
    border-left: 0.3px solid $color70-40;
  }
}
.p7_div {
  margin: 3rem 0;
  // height: 100%;
  &.middle {
    // width: 80%;
  }
  .p7_title {
    font-family: light;
    font-size: 2.4rem;
    color: $color5A;
    text-align: start;
  }
  .p7_content {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
    text-align: start;
    font-family: demibold;
    font-size: 2.4rem;
    color: $color4D;
    margin-top: 0.6rem;
    /deep/p {
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
      text-align: start;
      font-family: demibold;
      font-size: 2.4rem;
      color: $color4D;
      margin-top: 0.6rem;
    }
  }
}
.p8_div {
  width: 100%;
  height: auto;
  // background: $color5A;
}
.p9_div {
  display: flex;
  flex-direction: column;
  img {
    width: 5.6rem;
    height: auto;
    // background: $color5A;
  }
  p {
    margin: 3rem 0 2rem;
    font-family: demibold;
    font-size: 2.4rem;
    text-align: start;
  }
  .download {
    margin-top: 0;
    font-size: 2.4rem;
    font-family: normal;
    color: $textBlueColor;
    cursor: pointer;
  }
}
.top_view {
  width: 100%;
  img {
    height: 100rem;
  }
  .top_title_div {
    position: absolute;
    top: 32rem;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 130rem;
    .title {
      font-size: 5rem;
      font-family: medium;
      color: white;
      text-align: start;
      margin: 1.4rem 0 3.8rem;
      &.gary {
        color: $textGaryColor;
      }
    }
    .tag {
      font-family: normal;
      font-size: 2.4rem;
      color: white;
      border-radius: 5rem;
      background: $textBlueColor;
      width: fit-content;
      padding: 0.5rem 2rem;
    }
    .content {
      text-align: start;
      font-family: normal;
      font-size: 2.2rem;
      color: white;
      &.gary {
        color: $textGaryColor;
      }
    }
    .content2 {
      @extend.content;
    }
    .bottom_item {
      margin-top: 13rem;
    }
  }
}
@media (max-width: 1200px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .p1_bottom {
    justify-content: left;
    .bottom_div {
      &:not(:first-child) {
        margin-left: 4rem;
      }
    }
  }
  .top_view {
    img {
      height: auto;
    }
    .top_title_div {
      width: 85%;
      top: 20rem;
      .bottom_item {
        margin-top: 6rem;
      }
      .content2 {
        font-size: 2.7rem;
      }
    }
  }
  .p_div {
    width: 90%;
  }
  .p1_div {
    margin: 6rem auto 0;
    flex-direction: column;
    &.reverse {
      flex-direction: column;
    }
  }
  .p1_title {
    font-size: 4rem;
  }
  .p1_content {
    margin-top: 2.5rem;
  }
  .p2_left_top_img {
  }
  .p1_left {
    width: 100%;
  }
  .p1_right {
    width: 100%;
    margin-top: 5rem;
  }
  .p2_right {
    width: 100%;
    margin-top: 2rem;
    min-height: 0;
  }
  .p3_div {
    padding: 10rem 0 0;
  }
  .p4_div {
    height: auto;
    .p4_sub_div {
      padding: 1rem 3rem 2rem;
      img {
        height: 60vw;
        object-fit: cover;
      }
    }
  }
  .p5_div {
    flex-direction: column;
    .p5_sub_div {
      width: 100%;
      img {
        height: 100%;
      }
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
  .p6_div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    img {
      // height: 23rem;
      height: auto;
    }
    p {
      padding: 1.5rem;
      font-size: 2rem;
    }
  }
  .p7_col {
    &:not(:nth-child(4n + 1)) {
      padding-left: 0;
      border-left: none;
    }
    &:not(:nth-child(2n + 1)) {
      padding-left: 9rem;
      border-left: 0.3px solid $color70-40;
    }
  }
}
</style>