import i18n from "../../main"
export function getList() {
  var title = i18n.t('msg.test')
  console.log('navbar getList==', title)
  var list = [
    {
      title: i18n.t("msg.首页"),
      path: "/",
      children: [
        { title: i18n.t("msg.关于我们"), path: "/About", children: [], tag: '' },
        { title: i18n.t("msg.产品中心"), path: "/Products", children: [], tag: '' },
        { title: i18n.t("msg.新闻资讯"), path: "/News", children: [], tag: '' },
        { title: i18n.t("msg.联系我们"), path: "/CallUs", children: [], tag: '' },
      ],
    },
    {
      title: i18n.t("msg.关于我们"),
      path: "/About",
      children: [
        {
          title: i18n.t("msg.企业简介"),
          img: require("../../../static/img/nav_about_icon_1.png"),
          blue_img: require("../../../static/img/nav_about_icon_1_1.png"),
          path: "/About",
          children: [],
          tag: 'Introduction'
        },
        {
          title: i18n.t("msg.核心优势"),
          img: require("../../../static/img/nav_about_icon_2.png"),
          blue_img: require("../../../static/img/nav_about_icon_2_1.png"),
          path: "/About",
          children: [],
          tag: 'Advantages'
        },
        {
          title: i18n.t("msg.生产实力"),
          img: require("../../../static/img/nav_about_icon_3.png"),
          blue_img: require("../../../static/img/nav_about_icon_3_1.png"),
          path: "/About",
          children: [],
          tag: 'Production'
        },
        {
          title: i18n.t("msg.荣誉资质"),
          img: require("../../../static/img/nav_about_icon_4.png"),
          blue_img: require("../../../static/img/nav_about_icon_4_1.png"),
          path: "/About",
          children: [],
          tag: 'Honor'
        },
        {
          title: i18n.t("msg.产品证书"),
          img: require("../../../static/img/nav_about_icon_5.png"),
          blue_img: require("../../../static/img/nav_about_icon_5_1.png"),
          path: "/About",
          children: [],
          tag: 'Certificates'
        },
      ],
    },
    {
      title: i18n.t("msg.产品中心"),
      path: "/Products",
      children: [],
    },
    {
      title: i18n.t("msg.新闻资讯"),
      path: "/News",
      children: [
        { title: i18n.t("msg.企业新闻"), path: "/News", children: [], tag: '' },
        // { title: i18n.t("msg.行业动态"), path: "/News", children: [], tag: '' },
      ],
    },
    {
      title: i18n.t("msg.联系我们"),
      path: "/CallUs",
      children: [
        { title: i18n.t("msg.公司地址"), path: "/CallUs", children: [], tag: '' },
        { title: i18n.t("msg.联系方式"), path: "/CallUs", children: [], tag: '' },
      ],
    },
  ];
  return list
}