<template>
  <div>
    <img class="page_top_img top_img" :src="imgs[0].value" />
    <div class="empty_div2">
      <arc-bg />
      <div>
        <div class="product_nav_div">
          <div class="home_div title_padding flex">
            <div
              class="nav_box"
              v-for="(item, index) in types"
              :key="index"
              @click="navClick(index)"
              :class="path.split('-')[0] == item.id ? 'hover' : ''"
            >
              <img
                :src="path.split('-')[0] == item.id ? item.blueIcon : item.icon"
              />
              <p
                :class="path.split('-')[0] == item.id ? 'pHover' : ''"
                v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
              ></p>
            </div>
          </div>
        </div>
        <div class="product_sub">
          <div class="home_div title_padding product_sub_nav_div">
            <p
              class="sub_nav_box"
              :class="path.split('-')[1] == item.id ? 'hover' : ''"
              v-for="(item, index) in types[aboutSelectIndex].children"
              :key="index"
              @click="changeUrl(item.id)"
              v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
            ></p>
          </div>
        </div>
      </div>
      <div class="home_div title_padding">
        <div class="search_div">
          <el-input
            :placeholder="$t('msg.搜索产品')"
            suffix-icon="el-icon-search"
            v-model="input"
            @change="getList(path.split('-')[1])"
          >
          </el-input>
        </div>
      </div>
      <div class="home_div products_list_div">
        <el-row :gutter="40">
          <el-col :md="8" v-for="(item, index) in list" :key="index">
            <div
              class="product_box"
              @click="navTo('/ProductsDetail/id=' + item.id)"
            >
              <img :src="item.picPd" />
              <div class="bottom_div">
                <div>
                  <p class="name">
                    {{ $i18n.locale == "zh" ? item.key : item.keyEn }}
                  </p>
                  <p class="type">
                    {{
                      $i18n.locale == "zh"
                        ? getLastType(item.typeName)
                        : getLastType(item.typeNameEn)
                    }}
                  </p>
                </div>
                <p class="detail_box">{{ $t("msg.详情") }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ArcBg from "./view/ArcBg.vue";
import * as API from "@/api/index";
export default {
  components: {
    ArcBg,
  },
  data() {
    return {
      aboutSelectIndex: 0,
      hoverIndex: 0,
      types: [{ children: [] }],
      list: [],
      input: "",
      path: "",
      imgs: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("/Products") != -1) {
        console.log("Products==" + to.path);
        var param = this.$route.query.param;
        console.log("Products param:" + param);
        if (param) {
          this.path = param;
        }
        this.getTypeList();
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    var param = this.$route.query.param;
    this.path = param;
    console.log("init Products param:" + param);
    this.getTypeList();
    this.getImg();
  },
  methods: {
    getImg() {
      var p = { key: "产品中心顶部图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.types = res.data;
            if (this.path) {
              if (this.path.split("-")[1]) {
                this.getList(this.path.split("-")[1]);
              } else {
                for (var i = 0; i < this.types.length; i++) {
                  if (this.types[i].id == this.path.split("-")[0]) {
                    this.aboutSelectIndex = i;
                    this.path = this.path + "-" + this.types[i].children[0].id;
                    break;
                  }
                }
                this.getList(this.path.split("-")[1]);
              }
            } else {
              this.path = this.types[0].id + "-" + this.types[0].children[0].id;
              this.getList(this.types[0].children[0].id);
            }
            let url = this.changeURLParam(
              window.location.href,
              "param",
              this.path
            ); // 修改 URL 参数
            console.log("url", url); //修改完之后的地址
            history.pushState("", "", url); // 不刷新页面
          }
        })
        .catch(() => {});
    },
    getList(type) {
      var data = {
        key: this.input,
        pdType: type,
        page: 1,
        limit: 999,
      };
      API.getProducts(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.data.list;
          }
        })
        .catch(() => {});
    },
    getLastType(name) {
      if (name) {
        var list = name.slice(name.indexOf("/") + 1, name.length);
        console.log("getLastType==", list);
        return list;
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
    navEnter(index) {
      this.hoverIndex = index;
    },
    navClick(index) {
      this.aboutSelectIndex = index;
      this.path = this.types[index].id + "-" + this.types[index].children[0].id;
      let url = this.changeURLParam(window.location.href, "param", this.path); // 修改 URL 参数
      console.log("url", url); //修改完之后的地址
      history.pushState("", "", url); // 不刷新页面
      this.getList(this.types[index].children[0].id);
    },
    navLeave() {
      // for (var i = 0; i < this.types.length; i++) {
      //   if (this.types[i].id == this.path.split("-")[0]) {
      //     this.aboutSelectIndex = i;
      //     break;
      //   }
      // }
      this.hoverIndex = -1;
    },
    changeUrl(id) {
      var temp_path = this.types[this.aboutSelectIndex].id + "-" + id;
      let url = this.changeURLParam(window.location.href, "param", temp_path); // 修改 URL 参数
      console.log("url", url); //修改完之后的地址
      history.pushState("", "", url); // 不刷新页面
      // history.replaceState(null, null, url); // 替换地址栏
      // window.location.href = url; // 刷新页面
      this.path = temp_path;
      this.getTypeList();
    },
    changeURLParam(url, type, value) {
      let reg = eval("/([?|&]" + type + "=)[^&]*/gi");
      value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); // 移除首尾空格
      let url2;
      if (!value) {
        // remove
        url2 = url.replace(reg, "");
      } else {
        if (url.match(reg)) {
          // edit
          url2 = url.replace(reg, "$1" + value);
        } else {
          // add
          url2 = url + (url.indexOf("?") > -1 ? "&" : "?") + type + "=" + value;
        }
      }
      return url2;
    },
  },
};
</script>

<style lang="scss" scoped>
.search_div {
  display: flex;
  justify-content: end;
  padding-top: 5rem;
  .el-input {
    width: auto;
  }
  /deep/.el-input__inner {
    border-radius: 50px;
  }
}
.product_nav_div {
  border-bottom: $color70 solid 0.7px;
  box-sizing: border-box;
  padding-top: 5rem;
  .nav_box {
    padding-bottom: 2rem;

    &.hover {
      border-bottom: $textBlueColor solid 1px;
    }
    img {
      cursor: pointer;
      height: 3.8rem;
    }
    p {
      cursor: pointer;
      font-size: 2.4rem;
      font-family: demibold;
      margin-bottom: 1rem;
      margin-top: 1rem;
      line-height: 1;
      &.pHover {
        color: $textBlueColor;
      }
    }
    // &:hover {
    //   p {
    //     color: $textBlueColor;
    //   }
    // }
  }
}
.product_sub {
  background: $colorF5;
}
.product_sub_nav_div {
  display: flex;
  .sub_nav_box {
    cursor: pointer;
    margin: 3.5rem 12rem 3.5rem 0;
    font-size: 2.2rem;
    font-family: light;
    line-height: 2.6rem;
    &.hover {
      color: $textBlueColor;
    }
  }
}
.product_box {
  margin: 2.5rem 0;
  position: relative;
  background-size: cover;
  cursor: pointer;
  transition: all 0.5s;
  max-width: 57rem;
  width: 100%;
  height: 56rem;
  max-height: 100%;
  overflow: hidden;
  border-radius: 3rem;
  // margin: 0 2rem;
  img {
    object-fit: cover;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
  }

  &:hover {
    box-shadow: 10px 10px 8px 0px #00000029;
    img {
      transform: scale(1.2);
    }
    .detail_box {
      color: white;
      border: $textBlueColor solid 1px;
      background: $textBlueColor;
    }
  }
  .bottom_div {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 3rem;
    padding: 0 3rem;
    width: 100%;
    align-items: flex-end;
    box-sizing: border-box;
  }
  .name {
    font-size: 1.8rem;
    text-align: start;
  }
  .type {
    text-align: start;
    margin-top: 0.5rem;
    font-family: medium;
    font-size: 2rem;
  }
  .detail_box {
    transition: all 0.5s;
    font-size: 2rem;
    border: $textGaryColor solid 1px;
    border-radius: 5rem;
    padding: 0.5rem 4rem;
    height: fit-content;
  }
}
@media (max-width: 1200px) {
  .product_nav_div {
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    padding-top: 0;
    .nav_box {
      margin: 0 1rem;
      p {
        width: max-content;
        font-size: 2.2rem;
      }
    }
  }
  .product_box {
    max-width: none;
    height: 80vw;
    img {
      height: auto;
    }
  }
  .product_sub {
    overflow-x: scroll;
  }
  .product_sub_nav_div {
    // width: auto !important;
    text-align: center;
    white-space: nowrap;
    .sub_nav_box {
      margin: 3rem 0;
      padding: 0 3rem 0 0;
      box-sizing: border-box;
    }
  }
}
</style>
