import request from '@/axios/request'

// 查询回复记录
export function getAbout(params) {
    return request({
        url: '/companyinfo/list',
        method: 'get',
        params: params
    })
}

// 插入回复记录
export function createMsg (data) {
    return request({
        url: '/msg/createMsg',
        method: 'post',
        data: data
    })
}
export function getHomeBanner(params){
    return request({
        url: '/publicity/banner/list',
        method: 'get',
        params: params
    })
}
export function getPic(params){
    return request({
        url: '/picture/list',
        method: 'get',
        params: params
    })
}
export function getAboutCompany(params){
    return request({
        url: '/publicity/company/list',
        method: 'get',
        params: params
    })
}
export function getHonor(params){
    return request({
        url: '/publicity/honor/list',
        method: 'get',
        params: params
    })
}
export function getBusiness(params){
    return request({
        url: '/publicity/businessinfo/list',
        method: 'get',
        params: params
    })
}
export function getProductType(params){
    return request({
        url: '/product/type/tree',
        method: 'get',
        params: params
    })
}
export function getAllList(params){
    return request({
        url: '/product/alllist',
        method: 'get',
        params: params
    })
}
export function getProducts(params){
    return request({
        url: '/product/list',
        method: 'get',
        params: params
    })
}
export function getProductDetail(id){
    return request({
        url: `/product/info/${id}`,
        method: 'get',
    })
}
export function getNews(params){
    return request({
        url: '/activity/list',
        method: 'get',
        params: params
    })
}
export function getNewsDetail(id){
    return request({
        url: `/activity/info/${id}`,
        method: 'get',
    })
}
export function getIndustry(){
    return request({
        url: '/publicity/industry/list',
        method: 'get',
    })
}
export function getContacts(){
    return request({
        url: '/contacts/company',
        method: 'get',
    })
}
export function getHomeCases(params){
    return request({
        url: '/cases/list',
        method: 'get',
        params: params
    })
}