<template>
  <el-carousel
    class="outside_div"
    :autoplay="true"
    trigger="click"
    arrow="never"
    height="100%"
    ref="carousel"
  >
    <el-carousel-item v-for="(item, index) in List" :key="index">
      <v-touch
        :swipe-options="{ direction: 'horizontal' }"
        v-on:swipeleft="swiperright(index)"
        v-on:swiperight="swiperleft(index)"
        class="wrapper"
      >
        <div class="home_banner_div" @click="jumpTo(item.url)">
          <img :src="isMiniWindows ? item.state : item.img" />
          <div class="home_banner_txt_div">
            <p
              class="title_1"
              v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
            ></p>
            <p
              class="title_2"
              v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
            ></p>
            <p class="more_touch_box_b_3E" @click="jumpTo(item.url)">
              {{ $t("msg.查看详情") }}
            </p>
          </div>
        </div>
      </v-touch>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      List: [],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  created() {},
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getBannerList();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  destroyed() {},
  methods: {
    getBannerList() {
      API.getHomeBanner()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.List = res.page.list;
          }
        })
        .catch(() => {});
    },
    jumpTo(url) {
      window.open(url, "_blank");
    },
    navTo(path) {
      this.$router.push(path);
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      this.$refs.carousel.prev();
      //设置幻灯片的索引
      this.$refs.carousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      this.$refs.carousel.next();
      this.$refs.carousel.setActiveItem(index + 1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapper {
  height: 100%;
}

.outside_div {
  height: 101.6rem;
  width: 100%;
  z-index: 0;

  /deep/.el-carousel__indicators--horizontal {
    bottom: 10rem;
    padding: 12px 20px;
    width: max-content;
  }

  /deep/.el-carousel__button {
    width: 10rem;
    opacity: 1;
    background-color: white;
  }

  /deep/.el-carousel__indicator.is-active button {
    background-color: #1961AC;
    width: 22.5rem;
  }
}

.home_banner_div {
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home_banner_txt_div {
    margin: 0 auto;
    max-width: 1920px;
    width: 85%;
    position: absolute;
    left: 0;
    right: 0;
    top: 39rem;

    .title_1 {
      color: #3E3E3E;
      letter-spacing: 1px;
      text-align: start;
      font-size: 2.2rem;
      font-family: normal;
      line-height: 3rem;
    }

    .title_2 {
      line-height: 6.5rem;
      color: #1961AC;
      text-align: start;
      font-size: 6rem;
      font-family: medium;
      margin-top: 2.6rem;
      margin-bottom: 4rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home_banner_div {
    // img {
    // object-fit: contain;
    // }
    .home_banner_txt_div {
      // left: 5rem;
      width: 90%;
      top: 25rem;

      .title_1 {
        color: white;
        line-height: 17px;
      }

      .title_2 {
        color: white;
        font-size: 4.2rem;
        line-height: 30px;
      }
    }

    .more_touch_box_b_3E {
      font-size: 2.2rem;
      border: #1961AC solid 1px !important;
      border-radius: 5rem;
      padding: 1rem 4rem;
      width: fit-content;
      color: white;
      cursor: pointer;
      transition: all 0.5s;
      background-color: #1961AC !important;
    }
  }

  .outside_div {
    height: 91.6rem;

    /deep/.el-carousel__button {
      width: 5rem;
      opacity: 1;
      background-color: white;
    }

    /deep/.el-carousel__indicator.is-active button {
      background-color: #1961AC;
      width: 12.5rem;
    }
  }
}
</style>