d<template>
  <div>
    <img class="page_top_img top_img" :src="imgs[0].value" />
    <div class="empty_div2">
      <arc-bg />
      <div class="nav_menu">
        <div class="about_menu">
          <div
            v-for="(item, index) in nav_title[1].children"
            :key="index"
            @click="scrollNavto(item.path, item.tag)"
            @mouseenter="navEnter(index)"
            @mouseleave="navLeave"
            :class="path == item.tag ? 'hover' : ''"
          >
            <img
              :src="
                aboutSelectIndex == index || path == item.tag
                  ? item.blue_img
                  : item.img
              "
            />
            <p
              :class="
                aboutSelectIndex == index || path == item.tag ? 'hover' : ''
              "
            >
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
      <div class="home_div about_video_div">
        <el-row class="about_div">
          <el-col :md="16" :lg="16"
            ><div class="left_div">
              <!-- <img src="../../static/img/about_video_cover.png" /> -->
              <!-- <video></video> -->
              <video
                id="top-video"
                muted="muted"
                controls
                controlsList="nodownload"
                :src="company.video"
                width="100%"
                webkit-playsinline
                playsinline
                x5-video-player-type="h5"
                :poster="company.cover"
              ></video></div
          ></el-col>
          <el-col :md="8" :lg="8"
            ><div class="right_div">
              <p
                class="right_title"
                v-html="$i18n.locale == 'zh' ? company.title : company.titleEn"
              ></p>
              <p
                class="right_content"
                v-html="
                  $i18n.locale == 'zh' ? company.content : company.contentEn
                "
              ></p></div
          ></el-col>
        </el-row>
        <div class="home_about_title_div" id="Introduction">
          <div class="home_about_num_div only_pc">
            <p class="home_about_num">
              <countTo
                class="home_about_num"
                ref="mynum1"
                :startVal="0"
                :endVal="Number(company.years)"
                :autoplay="false"
                separator=""
              ></countTo>
              <span class="home_about_num_dan_2">{{ $t("msg.年") }}</span>
            </p>
            <p class="home_about_num_content">{{ $t("msg.公司成立于") }}</p>
          </div>

          <div class="home_about_num_div middle only_pc">
            <p class="home_about_num">
              <countTo
                class="home_about_num"
                ref="mynum2"
                :startVal="0"
                :endVal="Number(company.area)"
                :autoplay="false"
                separator=""
              ></countTo>
              <span class="home_about_num_dan_1">m<sup>2</sup></span>
            </p>
            <p class="home_about_num_content">{{ $t("msg.占地面积") }}</p>
          </div>

          <div class="home_about_num_div right only_pc">
            <p class="home_about_num">
              <countTo
                class="home_about_num"
                ref="mynum3"
                :startVal="0"
                :endVal="Number(company.people)"
                :autoplay="false"
                separator=""
              ></countTo>
              <span class="home_about_num_dan_3">+</span>
            </p>
            <p class="home_about_num_content">{{ $t("msg.名员工") }}</p>
          </div>

          <div class="home_about_num_div right only_pc">
            <p class="home_about_num">
              <countTo
                class="home_about_num"
                ref="mynum4"
                :startVal="0"
                :endVal="Number(company.country)"
                :autoplay="false"
                separator=""
              ></countTo>
              <span class="home_about_num_dan_3">+</span>
            </p>
            <p class="home_about_num_content">{{ $t("msg.出口国家") }}</p>
          </div>

          <div class="home_about_title_div_left only_phone flex">
            <div class="home_about_num_div">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum11"
                  :startVal="0"
                  :endVal="Number(company.years)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_2">{{ $t("msg.年") }}</span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.公司成立于") }}</p>
            </div>

            <div class="home_about_num_div">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum21"
                  :startVal="0"
                  :endVal="Number(company.people)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_3">+</span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.名员工") }}</p>
            </div>
          </div>
          <div class="home_about_title_div_right only_phone flex">
            <div class="home_about_num_div">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum31"
                  :startVal="0"
                  :endVal="Number(company.area)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_1">m<sup>2</sup></span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.占地面积") }}</p>
            </div>
            <div class="home_about_num_div">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum41"
                  :startVal="0"
                  :endVal="Number(company.country)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_3">+</span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.出口国家") }}</p>
            </div>
          </div>
        </div>
      </div>
      <branded id="Advantages"></branded>
      <div class="home_div title_padding">
        <p class="home_title">{{ $t("msg.生产实力") }}</p>
      </div>
      <about-swiper-3 id="Production"></about-swiper-3>
      <div class="gary_div">
        <!-- <div class="home_div title_padding top_margin" id="Honor">
        <p class="home_title bottom_margin_5">{{ $t("msg.荣誉资质") }}</p>
      </div> -->
        <about-swiper-1></about-swiper-1>
      </div>

      <about-swiper-4></about-swiper-4>
    </div>
  </div>
</template>
  
<script>
import * as API from "@/api/index";
import countTo from "vue-count-to";
import ArcBg from "./view/ArcBg.vue";
import * as navBar from "./navbar/navbar";
import Branded from "./view/Branded.vue";
import AboutSwiper4 from "./view/AboutSwiper4.vue";
import AboutSwiper3 from "./view/AboutSwiper3.vue";
import AboutSwiper1 from "./view/AboutSwiper1.vue";
export default {
  components: {
    ArcBg,
    countTo,
    Branded,
    AboutSwiper4,
    AboutSwiper3,
    AboutSwiper1,
  },
  data() {
    return {
      aboutSelectIndex: -1,
      company: "",
      oldScrollTop: 0,
      numScrollStart: false,
      path: "Introduction",
      imgs: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("/About") != -1) {
        console.log("about==" + to.path);
        var param = this.$route.query.param;
        this.path = param;
        console.log("watch about param:" + param);
        this.navtoPartPath(this.path);
      }
    },
  },
  created() {},
  computed: {
    nav_title() {
      return navBar.getList();
    },
  },
  mounted() {
    this.getImg();
    this.getAbout();
    var param = this.$route.query.param;
    if (param) {
      this.path = param;
      console.log("about param:" + param);
      this.navtoPartPath("");
    }
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    getImg() {
      var p = { key: "关于我们顶部图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getAbout() {
      API.getAbout()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.company = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    //判断介绍部分是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      console.log("oldScrollTop==" + this.oldScrollTop);
      var clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth < 1200) {
        if (this.oldScrollTop > 100) {
          this.handleScroll();
        }
      } else if (clientWidth < 1600) {
        if (this.oldScrollTop > 200) {
          this.handleScroll();
        }
      } else if(clientWidth < 1700) {
        if (this.oldScrollTop > 400) {
          this.handleScroll();
        }
      }else {
        if (this.oldScrollTop > 600) {
          this.handleScroll();
        }
      }
    },
    handleScroll() {
      if (!this.numScrollStart) {
        this.$refs.mynum1.start();
        this.$refs.mynum11.start();
        this.$refs.mynum2.start();
        this.$refs.mynum21.start();
        this.$refs.mynum3.start();
        this.$refs.mynum31.start();
        this.$refs.mynum4.start();
        this.$refs.mynum41.start();
      }
      this.numScrollStart = true;
    },
    navtoPartPath(path) {
      if (path) {
        document.getElementById(path).scrollIntoView(false);
      } else {
        // this.path = "Introduction";
        window.scrollTo(0, 0);
      }
      // setTimeout(() => {
      // }, 200);
    },
    scrollNavto(path, name) {
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    navEnter(index) {
      this.aboutSelectIndex = index;
    },
    navLeave() {
      this.aboutSelectIndex = -1;
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
  
<style lang="scss" scoped>
.gary_div {
  background: white;
}
.nav_menu {
  top: -6rem;
  border-bottom: $color70 solid 0.7px;
  position: relative;
}
.about_menu {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;

  &.show {
    opacity: 1;
    transition: all 0.5s;
  }

  div {
    margin: 0 8rem;
    cursor: pointer;
    padding-bottom: 2rem;

    &.hover {
      border-bottom: $textBlueColor solid 1px;
    }

    // &:hover {
    //   p {
    //     color: $textBlueColor;
    //   }
    // }

    p {
      margin-top: 0.5rem;
      font-size: 2.4rem;
      font-family: demibold;
      color: $textGaryColor;
      &.hover {
        color: $textBlueColor;
      }
    }

    img {
      height: 4.5rem;
    }
  }
}
.about_div {
  margin-top: 13rem;
  .left_div {
    margin-right: 6rem;
    height: 64rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4rem;
    }
    video {
      object-fit: cover;
      border-radius: 4rem;
      width: 100%;
      height: 100%;
    }
  }
  .right_div {
    .right_title {
      margin-top: 3.8rem;
      text-align: start;
      font-family: demibold;
      font-size: 5rem;
      line-height: 6.6rem;
      color: $textBlueColor;
    }
    .right_content {
      line-height: 1.5;
      margin-top: 8.5rem;
      text-align: start;
      font-size: 2.4rem;
    }
  }
}
.home_about_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  .home_about_title_div_left {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: $colorB1-40 solid 1px;
    padding-bottom: 8rem;
  }
  .home_about_title_div_right {
    @extend.home_about_title_div_left;
    margin-top: 6rem;
  }
}
.home_about_num_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 22rem;
  text-align: left;
  &.middle {
    min-width: 27.2rem;
  }
  &.right {
    min-width: 18rem;
  }
}

.home_about_num {
  font-size: 5.2rem;
  font-family: bold;
  color: $textBlueColor;
}

.home_about_num_dan_1 {
  // margin-left: 1.2rem;
  font-size: 2.8rem;
  font-family: bold;
  color: $textBlueColor;
}

.home_about_num_dan_2 {
  // margin-left: 1.2rem;
  font-size: 3.1rem;
  font-family: bold;
  color: $textBlueColor;
}

.home_about_num_dan_3 {
  // margin-left: 1.2rem;
  font-size: 4.3rem;
  font-family: bold;
  color: $textBlueColor;
}

.home_about_num_content {
  font-size: 2.5rem;
  text-align: start;
  font-family: normal;
  color: $color3D;
}

@media (max-width: 1200px) {
  .gary_div {
    background: #f4f2f3ff;
    padding: 2rem 0 10rem;
    margin-top: 10rem;
  }
  .nav_menu {
    top: -2rem;
    .about_menu {
      width: 100%;
      justify-content: space-evenly;
      div {
        margin: 0 1rem;
        img {
          height: 4rem;
        }
        p {
          font-size: 2.2rem;
        }
      }
    }
    .about_div {
      .left_div {
        margin-right: 0;
        height: 65vw;
      }
      .right_div {
        .right_title {
          margin-top: 8rem;
          font-size: 4rem;
          line-height: 4.5rem;
        }
        .right_content {
          margin-top: 5rem;
        }
      }
    }
  }
  .about_div {
    margin-top: 8rem;
    .left_div {
      margin: 0 3rem;
      height: 65vw;
      video {
        border-radius: 2rem;
      }
    }
    .right_div {
      margin: 0 3rem;
      .right_title {
        font-size: 4.5rem;
      }
      .right_content {
        margin-top: 5.5rem;
      }
    }
  }
  .home_about_title_div {
    flex-direction: column;
    margin: 6rem 3rem;
  }
  .gary_div {
    padding: 2rem 0;
  }
}
</style>
  