<template>
  <div class="swiper_bg" id="Honor">
    <div class="home_div title_padding top_margin bottom_margin_5 flex center">
      <p class="home_title">
        {{ $t("msg.荣誉资质") }}
      </p>
      <div class="page_btn_div">
        <div @click="slideToPrev()" class="prev"></div>
        <div class="next" @click="slideToNext()"></div>
      </div>
    </div>
    <!-- <div class="swiper-button-prev swiper-button-prev1">
        </div> -->
    <div class="middle_banner">
      <div class="swiper-container mySwiper mySwiper1" id="mySwiper1">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <!-- <div class="swiper-slide" v-for="(item, index) in List" :key="index"> -->
          <div class="swiper-slide" v-for="(item, index) in zzry" :key="index">
            <!-- <img src="../../static/img/3A证书.png" /> -->
            <img class="book1_img" :src="item.img" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <p class="swiper_title">
          {{ $i18n.locale == "zh" ? bannertext : bannertextEn }}
        </p>
      </div>
      <!-- <div class="swiper-button-next swiper-button-next1">
      </div> -->
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
import Swiper, { Pagination } from "swiper";
Swiper.use(Pagination);
import "swiper/swiper-bundle.css";
export default {
  props: {
    language: {
      type: String,
      default: "",
      swiper: Object,
    },
  },
  watch: {
    //监听轮播数据变化后再创建实例
    zzry() {
      this.$nextTick(() => {
        let that = this;
        this.swiper = new Swiper(".mySwiper1", {
          slidesPerView: 3,
          spaceBetween: 10,
          centeredSlides: true,
          loop: true,
          autoplay: {
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
          },
          pagination: {
            el: ".swiper-pagination",
          },
          on: {
            activeIndexChange: function () {
              //alert("改变了，activeIndex为" + this.activeIndex);
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.changetext(this.realIndex);
            },
          },
          breakpoints: {
            "@0.00": {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            // "@1.50": {
            //   slidesPerView: 3,
            //   spaceBetween: 50,
            // },
          },
        });
      });
    },
  },
  data() {
    return {
      bannertext: "",
      bannertextEn: "",
      zzry: [],
      swiper: Object,
    };
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {},
  created() {},
  methods: {
    getInfo() {
      var data = { limit: 1000 };
      API.getHonor(data)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.zzry = res.page.list;
          }
        })
        .catch(() => {});
    },
    changetext(i) {
      this.bannertext = this.zzry[i].title;
      this.bannertextEn = this.zzry[i].titleEn;
    },
    slideToNext() {
      // var swiper = document.getElementById('mySwiper1');
      this.swiper.slideNext();
      // this.$mySwiper1.slideTo(1);
    },
    slideToPrev() {
      this.swiper.slidePrev();
    },
  },
};
</script>
<style lang="stylus" scoped>
.page_btn_div {
  display: flex;

  img {
    width: 5rem;
    height: 5rem;
    margin: 0 1rem;
    cursor: pointer;
  }

  .prev {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../static/img/swiper_left.png');

    &:hover {
      background-image: url('../../../static/img/swiper_left2.png');
    }
  }

  .next {
    cursor: pointer;
    margin-left: 1rem;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../static/img/swiper_right.png');

    &:hover {
      background-image: url('../../../static/img/swiper_right2.png');
    }
  }
}

.book1_img {
  transition: 300ms;
  width: 100%;
  height: 41.5rem;
  object-fit: cover;
  border-radius: 2rem;
  // transform: scale(0.7);
}

.book1_img:hover {
  // transform: scale(1.0);
}

.swiper_bg {
  // margin: 5rem 0;
  // padding: 3rem 0;
}

.swiper_title {
  margin: 5rem 0 0;
  font-family: demibold;
  font-size: 2.6rem;
  text-align: center;
}

.bannerright {
  width: 8rem;
  cursor: pointer;
  z-index: 99;
  padding: 5rem 3rem;
  border-radius: 6px;
}

.bannerleft {
  width: 8rem;
  cursor: pointer;
  z-index: 99;
  padding: 5rem 3rem;
  border-radius: 6px;
}

.bannerleft:hover, .bannerright:hover {
  background: rgba(0, 0, 0, 0.05);
}

.swiper-button-prev {
  // background-image: url('../../../static/img/资源45.png');
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 10rem;
  height: 10rem;
  // margin-left: 10rem;
  outline: none;
}

.swiper-button-prev:hover {
  // background-image: url('../../static/img/bannerlefthover.png');
}

.swiper-button-next {
  // background-image: url('../../../static/img/资源452.png');
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 10rem;
  height: 10rem;
  // margin-right: 10rem;
  outline: none;
}

.swiper-button-next:hover {
  // background-image: url('../../static/img/bannerrighthover.png');
}

.mySwiper {
  width: 100%;
  margin-bottom: 15rem;
  // margin: 3rem 8rem;
}

.middle_banner {
  max-width: 1920px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  border-radius: 2rem;
  background: white;
  padding: 1rem;
  box-sizing: border-box;
  // margin:2rem 0;
  transform: scale(0.8);
  box-shadow: 1rem 1rem 2rem 1px #00000029;

  img {
    // width: 40rem;
    // height: 25rem;
  }
}

.swiper-slide:hover {
  transform: scale(0.9);
}

.swiper-pagination {
  display: none;
}

// .swiper-slide-active, .swiper-slide-duplicate-active {
// transform: scale(1);
// }
@media screen and (max-width: 1200px) {
  .mySwiper {
    margin-bottom: 5rem;
  }

  .page_btn_div {
    display: none;
  }

  .swiper-pagination {
    display: block;
  }

  .swiper-slide {
    width: 85%;
    transform: scale(0.95);
  }

  .swiper-slide:hover {
    transform: scale(0.95);
  }

  .swiper_title {
    margin: 5rem 0 10rem;
  }

  .book1_img {
    height:auto;
  }

  .middle_banner {
    margin-top: 8rem !important;
    width: 100%;
  }

  .swiper-button-prev {
    display: none;
    // width:auto;
    // left:1px;
  }

  .swiper-button-next {
    display: none;
    // width:auto;
    // right:1px;
  }

  .bannerleft {
    width: 1rem;
    padding: 0;
  }

  .bannerright {
    width: 1rem;
    padding: 0;
  }
}
</style>
