<template>
  <div class="footer-father">
    <div class="footer-top">
      <img src="../../../static/img/logo2.png" />
    </div>
    <div class="nav_footer">
      <div class="footer">
        <div v-for="(item, i) in nav_title_list" :key="i" class="footer-sub">
          <div>
            <p class="title" @click="navto(item.path)">{{ item.title }}</p>
            <p
              class="content"
              v-for="sub in item.children"
              :key="sub.title"
              @click="scrollNavto(i, sub.path, sub.tag)"
            >
              {{ sub.title }}
            </p>
            <p
              v-if="i == 2"
              class="content"
              v-for="sub in product_list"
              :key="sub.title"
              @click="scrollNavto(i, item.path, sub.id)"
            >
              {{ $i18n.locale == "zh" ? sub.title : sub.titleEn }}
            </p>
          </div>
          <!-- <div class="line" v-show="i<list.length-1"></div> -->
        </div>
      </div>

      <div class="qrcode-div">
        <div>
          <!-- <p class="title">{{ $t("msg.服务热线") }}</p> -->
          <!-- <p class="num">{{contact[0].phone}}</p> -->
          <p class="content2" v-html="contact.code"></p>
        </div>
        <div>
          <img
            class="qrcode"
            :src="
              activeQrcode == 0
                ? contact.pic1
                : activeQrcode == 1
                ? contact.pic2
                : contact.pic3
            "
          />
          <div class="qrcode_icon_div">
            <img
              v-if="contact.pic1"
              src="../../../static/img/footer_ali_icon.png"
              @mouseenter="qrcode(0)"
            />
            <img
              v-if="contact.pic2"
              src="../../../static/img/footer_wechat_icon.png"
              @mouseenter="qrcode(1)"
            />
            <img
              v-if="contact.pic3"
              src="../../../static/img/footer_qq_icon.png"
              @mouseenter="qrcode(2)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <!-- <div class="line2"></div> -->
      <p class="content" style="display: flex">
        {{ $t("msg.版权所有") }}
        <a href="https://beian.miit.gov.cn/">浙ICP备2022034839号</a>
      </p>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/index";
import * as navBar from "./navbar";
export default {
  data() {
    return {
      contact: [],
      product_list: [],
      activeQrcode: 0,
    };
  },
  computed: {
    nav_title_list() {
      return navBar.getList();
    },
  },
  components: {},
  mounted() {
    this.Init();
  },

  methods: {
    Init() {
      this.getData();
      this.getTypeList();
    },
    getData() {
      API.getContacts()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.contact = res.data[0];
          }
        })
        .catch(() => {});
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.product_list = res.data;
            // this.nav_title_list[2].children = this.product_list;
          }
        })
        .catch(() => {});
    },
    qrcode(i) {
      this.activeQrcode = i;
    },
    navto(path) {
      this.$router.push(path);
    },
    scrollNavto(index, path, name) {
      if (index == 0) {
        this.navto(path);
      } else {
        this.$router.push({
          path: `${path}`,
          query: { param: name },
        });
      }
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped></style>
