<template>
  <div id="app">
    <el-container>
      <el-header>
        <nav-bar></nav-bar>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view :key="$route.fullpath"></router-view>
        </keep-alive>
      </el-main>
      <el-footer>
        <nav-footer></nav-footer>
      </el-footer>
    </el-container>
    <img
      src="../../static/img/go_top.png"
      class="goback_top_img"
      :class="isShow ? 'show' : 'no_show'"
      @click="goTopback()"
    />
  </div>
</template>

<script>
import NavBar from "./navbar/NavBar.vue";
import NavFooter from "./navbar/NavFooter.vue";
export default {
  components: {
    NavBar,
    NavFooter,
  },
  name: "App",
  data() {
    return {
      oldScrollTop: 0,
      isShow: false,
    };
  },
  created() {},
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    goTopback() {
      window.scrollTo(0, 0);
    },
    //判断返回顶部按钮是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      var pageHeight = document.body.scrollHeight;
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      // console.log("pageHeight==" + pageHeight);
      if (this.oldScrollTop > pageHeight / 3) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="stylus" scoped>
.goback_top_img {
  cursor: pointer;
  position: fixed;
  right: 5rem;
  width: 5rem;
  height: 5rem;
  bottom: 25%;
  z-index: 9999;
}

.show {
  transition: all 0.5s;
  opacity: 1;
}

.no_show {
  opacity: 0;
  transition: all 0.5s;
}

@media (max-width: 1200px) {
  .goback_top_img {
    right: 0.5rem;
  }
}
</style>
