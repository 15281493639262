<template>
  <div>
    <el-carousel
      class="banner"
      indicator-position="outside"
      :autoplay="false"
      trigger="click"
      arrow="never"
      height="100%"
      ref="procarousel"
    >
      <el-carousel-item v-for="(item, index) in List" :key="index">
        <v-touch
          :swipe-options="{ direction: 'horizontal' }"
          v-on:swipeleft="swiperright(index)"
          v-on:swiperight="swiperleft(index)"
          class="wrapper"
        >
          <div class="home_product_box">
            <img :src="item.pic" />
            <div class="bottom_div">
              <p class="type">
                {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
              </p>
              <p class="detail_box" @click="navTo('/Products', item.id)">
                {{ $t("msg.更多") }}
              </p>
            </div>
          </div>
        </v-touch>
      </el-carousel-item>
    </el-carousel>
    <p class="more_about_box" @click="navTo('/Products')">
      {{ $t("msg.查看全部") }}
    </p>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      List: [],
    };
  },
  created() {},
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getBannerList();
  },
  destroyed() {},
  methods: {
    getBannerList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.List = res.data;
          }
        })
        .catch(() => {});
    },
    jumpTo(url) {
      window.open(url, "_blank");
    },
    navTo(path, param) {
      if (param) {
        this.$router.push({
          path: `${path}`,
          query: { param: param },
        });
      } else {
        this.$router.push(path);
      }
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      this.$refs.procarousel.prev();
      //设置幻灯片的索引
      this.$refs.procarousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      this.$refs.procarousel.next();
      this.$refs.procarousel.setActiveItem(index + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  margin-top: 36px;
}
.home_product_box {
  margin: 0;
  height:auto;
}
.home_product_box:hover {
  box-shadow: none;
  img {
    transform: none;
  }
}
.more_about_box {
  cursor: pointer;
  margin: 5rem auto;
  font-size: 2.2rem;
}
.outside_div {
  height: auto;
  width: 100%;
  z-index: 0;
}
/deep/.el-carousel__container {
  height: 35em !important;
}
/deep/.el-carousel__button {
  width: 1rem;
  height: 1rem;
  opacity: 1;
  border-radius: 50%;
  background-color: #d2d2d2;
}

/deep/.el-carousel__indicator.is-active button {
  background-color: #1961ac;
}
.home_product_box .type {
  color: #1961ac;
  font-size: 3.5rem;
}
.detail_box {
  color: white;
  border: #1961ac solid 1px !important;
  background: #1961ac;
}

// @media screen and (max-width: 1200px) {

// }
</style>