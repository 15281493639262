export const msg = {
    test:'English',
    首页:'Home',
    关于我们:'About US',
    产品中心:'Products',
    新闻资讯:'News',
    联系我们:'Contact Us',
    企业简介:'Introduction of Hecai',
    核心优势:'Main Advantages',
    生产实力:'Production Devices',
    荣誉资质:'Honor & Reputation',
    产品证书:'Certificates',
    企业新闻:'Company News',
    行业动态:'Industry News',
    公司地址:'Location',
    联系方式:'Contacts',
    产品系列:'Products',
    更多:'More',
    详细内容:'Details',
    了解详情:'Details',
    查看详情:'Details',
    详情:'Details',
    占地面积:'Factory Area',
    年:'Year',
    公司成立于:'Founded in',
    名员工:'Employees',
    更多新闻:'More News',
    出口国家:'Export to Countries',
    产品细节:'Product details',
    产品参数:'Specifications',
    产品视频:'Product videos',
    文档说明书:'Documentation',
    地址:'Address',
    电话:'International Dept',
    内销部:'Domestic Sales Dept',
    传真:'FAX',
    邮箱:'E-mail',
    网址:'Website',
    返回:'Back',
    上一条:'Prev',
    下一条:'Next',
    点击下载:'Download',
    下载:'Download',
    服务热线:'24小时服务热线',
    查看全部:'All',
    版权所有:'COPYRIGHT @ NINGBO HECAI MEDICAL EQUIPMENT CO., LTD.',
    观看产品视频:'Video',
    搜索:'Search',
    搜索产品:'Search Products',
    装箱清单:'Packing list',
}