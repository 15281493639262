<template>
  <div>
    <home-banner class="top_banner" />
    <div class="empty_div">
      <arc-bg />
      <div class="home_div title_padding">
        <p class="home_title">{{ $t("msg.产品系列") }}</p>
      </div>
      <div class="home_div content_padding">
        <home-product-banner class="only_phone"></home-product-banner>
        <el-row :gutter="30" class="home_product_div">
          <el-col
            :xs="24"
            :md="8"
            v-for="(item, i) in types"
            :key="i"
            v-if="i < 3"
          >
            <div class="home_product_box" @click="navto('/Products', item.id)">
              <img :src="item.pic" />
              <div class="bottom_div">
                <p class="type">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
                <p class="detail_box" @click="navto('/Products', item.id)">
                  {{ $t("msg.更多") }}
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="home_product_div sub" >
          <el-col
            :xs="24"
            :md="6"
            v-for="(item, i) in types"
            :key="i"
            v-if="i > 2"
          >
            <div class="home_product_box sub" @click="navto('/Products', item.id)">
              <img :src="item.pic" />
              <div class="bottom_div">
                <p class="type">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
                <p class="detail_box" @click="navto('/Products', item.id)">
                  {{ $t("msg.更多") }}
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="home_about_div">
        <div
          class="home_about_box"
          data-aos="fade-up"
          data-aos-duration="3000"
          id="home_about_box"
        >
          <div class="home_about_title_div">
            <p class="home_about_title">
              {{
                $i18n.locale == "zh" ? company.homeTitle : company.homeTitleEn
              }}
            </p>
            <p class="more_about_box" @click="navto('/About')">
              {{ $t("msg.了解详情") }}
            </p>
          </div>
          <p class="home_about_content">
            {{
              $i18n.locale == "zh" ? company.homeContent : company.homeContentEn
            }}
          </p>
          <div class="home_about_title_div">
            <div class="home_about_num_div">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum1"
                  :startVal="0"
                  :endVal="Number(company.area)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_1">m<sup>2</sup></span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.占地面积") }}</p>
            </div>
            <div class="home_about_num_div middle">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum2"
                  :startVal="0"
                  :endVal="Number(company.years)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_2">{{ $t("msg.年") }}</span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.公司成立于") }}</p>
            </div>
            <div class="home_about_num_div right">
              <p class="home_about_num">
                <countTo
                  class="home_about_num"
                  ref="mynum3"
                  :startVal="0"
                  :endVal="Number(company.people)"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="home_about_num_dan_3">+</span>
              </p>
              <p class="home_about_num_content">{{ $t("msg.名员工") }}</p>
            </div>
          </div>
          <img class="home_about_bottom_img" :src="imgs[0].value" />
        </div>
      </div>
      <div class="home_acc_div">
        <div class="home_div title_padding top_margin">
          <p class="home_title">{{ $t("msg.生产实力") }}</p>
        </div>
        <!-- <div class="home_div title_padding"> -->
        <div class="only_pc">
          <accordion-view></accordion-view>
        </div>
        <div class="only_phone">
          <about-swiper-2></about-swiper-2>
        </div>
      </div>
      <!-- </div> -->
      <div class="home_div title_padding">
        <p class="home_title center">{{ $t("msg.新闻资讯") }}</p>
        <div class="home_news_div">
          <div class="home_news_div_left">
            <img :src="news[0].cover" @click="navto('/NewsDetail/id=' + news[0].id)"/>
            <div class="home_news_left_bottom" @click="navto('/NewsDetail/id=' + news[0].id)">
              <div class="home_news_box">
                <div class="home_news_title">
                  <p class="p_type">
                    {{ $i18n.locale == "zh" ? news[0].type : news[0].typeEn }}
                  </p>
                  <p>&ensp;|&ensp;</p>
                  <p>{{ news[0].activityTime }}</p>
                </div>
                <p
                  class="home_news_content"
                  @click="navto('/NewsDetail/id=' + news[0].id)"
                >
                  {{ $i18n.locale == "zh" ? news[0].title : news[0].titleEn }}
                </p>
                <div class="before"></div>
              </div>
            </div>
          </div>
          <div class="home_news_div_right">
            <div class="home_news_list">
              <div class="home_news_box phone">
                <img :src="news[0].cover" />
                <div class="home_news_title">
                  <p class="p_type">
                    {{ $i18n.locale == "zh" ? news[0].type : news[0].typeEn }}
                  </p>
                  <p>&ensp;|&ensp;</p>
                  <p>{{ news[0].activityTime }}</p>
                </div>
                <p
                  class="home_news_content"
                  @click="navto('/NewsDetail/id=' + news[0].id)"
                >
                  {{ $i18n.locale == "zh" ? news[0].title : news[0].titleEn }}
                </p>
              </div>
              <div
                class="home_news_box"
                v-for="(item, i) in news.slice(1, news.length)"
                :key="i"
                @click="navto('/NewsDetail/id=' + item.id)"
              >
                <div class="home_news_title">
                  <p class="p_type">
                    {{ $i18n.locale == "zh" ? item.type : item.typeEn }}
                  </p>
                  <p>&ensp;|&ensp;</p>
                  <p>{{ item.activityTime }}</p>
                </div>
                <p class="home_news_content">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
              </div>
            </div>
            <p class="more_touch_box" @click="navto('/News')">
              {{ $t("msg.更多新闻") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import * as API from "@/api/index";
import HomeBanner from "./view/HomeBanner.vue";
import HomeProductBanner from "./view/HomeProductBanner.vue";
import ArcBg from "./view/ArcBg.vue";
import AccordionView from "./view/AccordionView.vue";
import AboutSwiper2 from "./view/AboutSwiper2.vue";
export default {
  data() {
    return {
      oldScrollTop: 0,
      numScrollStart: false,
      company: "",
      news: [],
      imgs: [],
      types: [{}, {}, {}, {}, {}, {}, {}],
    };
  },
  components: {
    HomeBanner,
    HomeProductBanner,
    ArcBg,
    AccordionView,
    AboutSwiper2,
    countTo,
  },
  watch: {
    $route(to, from) {
      if (to.path == "/") {
        window.scrollTo(0, 0);
      }
    },
  },
  mounted() {
    this.Init();
    window.scrollTo(0, 0);
  },

  methods: {
    navto(path, param) {
      if (param) {
        this.$router.push({
          path: `${path}`,
          query: { param: param },
        });
      } else {
        this.$router.push(path);
      }
    },
    Init() {
      this.getImg();
      this.getAbout();
      this.getNews();
      this.getTypeList();
      // 监听页面滚动事件
      window.addEventListener("scroll", this.scrolling);
      const ele = document.querySelector("#home_about_box");
      console.log("ele==", ele);
    },
    getImg() {
      var p = { key: "关于我们顶部图" };
      API.getPic(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    getAbout() {
      API.getAbout()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.company = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    getNews() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.news = res.page.list;
          }
        })
        .catch(() => {});
    },
    //判断介绍部分是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      var clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth < 1600) {
        if (this.oldScrollTop > 600) {
          this.handleScroll();
        }
      } else if(clientWidth < 1700) {
        if (this.oldScrollTop > 1200) {
          this.handleScroll();
        }
      }else {
        if (this.oldScrollTop > 1800) {
          this.handleScroll();
        }
      }
    },
    handleScroll() {
      if (!this.numScrollStart) {
        this.$refs.mynum1.start();
        this.$refs.mynum2.start();
        this.$refs.mynum3.start();
      }
      this.numScrollStart = true;
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.types = res.data;
          }
        })
        .catch(() => {});
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.home_about_div {
  height: 106.6rem;
  background: url("../../static/img/home_about_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  background-position: center;
  padding: 8rem 0;

  .home_about_box {
    box-sizing: border-box;
    padding: 10rem 15rem;
    width: 85%;
    max-width: 1920px;
    margin: 8rem auto 0;
    border-radius: 4rem 4rem 0 0;
    background-image: linear-gradient($textWhiteColor60, transparent);
    .home_about_bottom_img {
      display: none;
      height: 45rem;
      width: 100%;
      margin-top: 3rem;
      border-radius: 3rem;
    }
    .home_about_num_div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
      min-width: 27.3rem;

      &.middle {
        min-width: 22rem;
      }
      &.right {
        min-width: 18rem;
      }
    }

    .home_about_num {
      font-size: 5.6rem;
      font-family: bold;
      color: $textBlueColor;
    }

    .home_about_num_dan_1 {
      margin-left: 1.2rem;
      font-size: 3.5rem;
      font-family: bold;
      color: $textBlueColor;
    }

    .home_about_num_dan_2 {
      margin-left: 1.2rem;
      font-size: 3.1rem;
      font-family: bold;
      color: $textBlueColor;
    }

    .home_about_num_dan_3 {
      margin-left: 1.2rem;
      font-size: 4.3rem;
      font-family: bold;
      color: $textBlueColor;
    }

    .home_about_num_content {
      font-size: 2.8rem;
      font-family: normal;
      color: $color3D;
    }

    .home_about_title_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .home_about_title {
      font-size: 5.6rem;
      font-family: demibold;
      color: $color3E;
      text-align: start;
    }

    .home_about_content {
      color: $color3E;
      text-align: start;
      margin-top: 6rem;
      margin-bottom: 9rem;
      font-size: 2.7rem;
      font-family: normal;
      line-height: 4.3rem;
    }
  }
}

.home_news_div {
  margin-top: 7rem;
  display: flex;
  height: 68rem;
  justify-content: center;

  .home_news_div_left {
    height: 62rem;
    position: relative;

    img {
      cursor: pointer;
      border-radius: 3rem;
      width: 100rem;
      height: 100%;
      object-fit: cover;
    }

    .home_news_left_bottom {
      width: 75.5rem;
      border-radius: 9rem 0 9rem 0;
      background: $textBlueColor;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: -6rem;
      left: -10rem;

      .home_news_box {
        padding: 3.5rem 0;
        width: 75%;
        border-radius: 0 0 5rem 0;
        background: $colorF5;
        cursor: pointer;
        box-sizing: border-box;
        transition: all ease 0.5s;

        .before {
          z-index: 10;
          border-radius: 0 0 5rem 0;
          width: 0%;
          height: 100%;
          background-color: $textBlueColor;
          position: absolute;
          bottom: 0;
          transition: all ease 0.4s;
        }
        .home_news_title {
          position: relative;
          margin: 0 5rem;
          z-index: 1000;
          display: flex;

          p {
            font-size: 1.6rem;
            font-family: regular;
          }

          .p_type {
            color: $textBlueColor;
          }
        }

        .home_news_content {
          position: relative;
          z-index: 1000;
          margin: 1.8rem 5rem 0;
          width: 90%;
          font-family: demibold;
          font-size: 2.2rem;
          text-align: start;
        }
      }

      .home_news_box:hover {
        .before {
          transition: all ease 0.5s;
          width: 75%;
        }
        p {
          color: white !important;
        }
      }
    }
  }

  .home_news_div_right {
    height: 62rem;
    width: 30%;
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .home_news_list {
      height: 50rem;
      overflow: auto;
    }

    .home_news_list::-webkit-scrollbar {
      width: 0;
    }

    .home_news_list::-webkit-scrollbar-thumb {
      width: 0;
    }

    .home_news_box {
      cursor: pointer;
      border-bottom: $color70 solid 1px;
      box-sizing: border-box;
      padding-bottom: 7rem;

      &.phone {
        display: none;
      }

      .home_news_title {
        display: flex;

        p {
          font-size: 1.6rem;
          font-family: regular;
        }

        .p_type {
          color: $textBlueColor;
        }
      }

      .home_news_content {
        width: 90%;
        margin-top: 1.8rem;
        font-family: demibold;
        font-size: 2.2rem;
        text-align: start;
      }
    }

    .home_news_box:not(:first-child) {
      margin-top: 3.5rem;
    }
  }
}
@media screen and (max-width: 1200px) {
  .home_about_div {
    background: none;
    height: auto;
    .home_about_box {
      width: 90%;
      max-width: 90%;
      padding: 0;
      margin: 0 auto;
      background-image: none;

      .home_about_num_div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        width: 36%;
        min-width: 0;

        &.middle {
          min-width: 0;
          width: 30%;
        }
        &.right {
          width: 25%;
          min-width: 0;
        }
      }
      .home_about_bottom_img {
        display: block;
        object-fit: cover;
        height: 70vw;
      }
      .home_about_title_div {
        .more_about_box {
          display: none;
        }
      }
      .home_about_title {
        color: $textBlueColor;
        font-size: 4.5rem;
      }
      .home_about_content {
        font-size: 2.2rem;
        margin-top: 6.5rem;
        margin-bottom: 6.5rem;
      }
      .home_about_num {
        font-size: 4rem;
      }
      .home_about_num_content {
        font-size: 2rem;
      }
      .home_about_num_dan_1 {
        font-size: 2rem;
        margin-left: 0;
      }
      .home_about_num_dan_2 {
        font-size: 2rem;
        margin-left: 0;
      }
      .home_about_num_dan_3 {
        font-size: 2rem;
        margin-left: 0;
      }
    }
  }
  .home_acc_div {
    padding: 1rem 0 8rem 0;
    background: #f4f2f3;
    margin-bottom: 14rem;
  }
  .home_news_div {
    justify-content: flex-end;
    flex-direction: column;
    height: auto;
    .home_news_div_left {
      display: none;
    }
    .home_news_div_right {
      margin-left: 0;
      height: max-content;
      width: 100%;
      .home_news_list {
        height: auto;
      }
      .home_news_box {
        img {
          display: block;
          width: 100%;
          height: 32rem;
          border-radius: 3rem;
          margin-bottom: 5rem;
          object-fit: cover;
        }
        &.phone {
          display: block;
        }
        .home_news_title {
          .p_type {
            font-size: 2rem;
          }
          p {
            font-size: 2rem;
          }
        }

        .home_news_content {
          font-size: 3rem;
        }
      }
    }
    .more_touch_box {
      font-size: 2.2rem;
      margin-top: 8rem;
      color: white;
      background: $textBlueColor;
      border: none;
    }
  }
}
</style>
