<template>
  <div>
    <div class="detail_div">
      <p class="detail_title">
        {{ $i18n.locale == "zh" ? Detail.title : Detail.titleEn }}
      </p>
      <p class="detail_time">
        <span class="detail_type">{{
          $i18n.locale == "zh" ? Detail.type : Detail.typeEn
        }}</span>
        |
        {{ Detail.activityTime }}
      </p>
      <div
        class="detail_content"
        v-html="$i18n.locale == 'zh' ? Detail.content : Detail.contentEn"
      ></div>
    </div>
    <div class="bottom_div">
      <p class="news_detail_more_touch_box" @click="goback">
        {{ $t("msg.返回") }}
      </p>
      <div class="right_div">
        <p class="news_detail_more_touch_box_left" @click="goPrve">
          {{ $t("msg.上一条") }}
        </p>
        <p class="news_detail_more_touch_box_right" @click="goNext">
          {{ $t("msg.下一条") }}
        </p>
      </div>
    </div>
  </div>
</template>
  <script>
import * as API from "@/api/index";
export default {
  components: {},
  data() {
    return {
      Detail: "",
      list: "",
    };
  },
  watch: {
    $route(to, from) {
      //   console.log(to.path);
      if (to.path.indexOf("/NewsDetail") != -1) {
        window.scrollTo(0, 0);
        this.getDetail();
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getDetail();
    this.getList();
  },
  methods: {
    getList() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
          }
        })
        .catch(() => {});
    },
    goNext() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (item.id == news_id && i + 1 < this.list.length) {
          next_id = this.list[i + 1].id;
          break;
        }
      }
      console.log("goNext");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    goPrve() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (i > 0 && item.id == news_id) {
          next_id = this.list[i - 1].id;
          break;
        }
      }
      console.log("goPrve");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    getDetail() {
      let news_id = window.location.href.split("id=")[1];
      API.getNewsDetail(news_id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.data;
          }
        })
        .catch(() => {});
    },
    goback() {
      // this.$router.back();
      this.$router.push("/News");
    },
  },
};
</script>
  <style lang="scss" scoped>
.detail_div {
  // padding:20rem 45rem;
  margin: 20rem 45rem;
  .detail_title {
    font-family: demibold;
    font-size: 5rem;
    color: $textBlueColor;
  }
  .detail_type {
    font-family: light;
    font-size: 1.6rem;
    color: $textBlueColor;
    margin: 6rem 0;
  }
  .detail_time {
    font-family: light;
    font-size: 1.6rem;
    color: $color67;
    margin: 6rem 0;
  }
  /deep/ .detail_content {
    p {
      font-family: normal;
      font-size: 1.8rem;
    }
    img {
      max-width: 100%;
    }
  }
}
.bottom_div {
  width: 90%;
  margin: 5rem auto 30rem;
  display: flex;
  position: relative;
  justify-content: center;
  .right_div {
    position: absolute;
    right: 0;
    display: flex;
    p:nth-child(2) {
      margin-left: 5rem;
    }
  }
}
.news_detail_more_touch_box {
  font-size: 1.6rem;
  border: $textGaryColor solid 1px;
  border-radius: 5rem;
  padding: 1rem 4rem;
  width: fit-content;
  min-width: 14.5rem;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
  box-sizing: border-box;

  &:hover {
    transition: all 0.5s;
    border: $textBlueColor solid 1px;
    background-color: $textBlueColor;
    color: white;
  }
}

.news_detail_more_touch_box_left {
  @extend.news_detail_more_touch_box;
  padding: 1rem 4rem 1rem 5rem;
  position: relative;
  &::before {
    content: "";
    width: 1.2rem;
    height: 0.65rem;
    background: url("../../static/img/news_icon_left.png");
    background-size: cover;
    background-origin: border-box;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 25%;
  }
}
.news_detail_more_touch_box_right{
  @extend.news_detail_more_touch_box;
  padding: 1rem 5rem 1rem 4rem;
  position: relative;
  &::after {
    content: "";
    width: 1.2rem;
    height: 0.65rem;
    background: url("../../static/img/news_icon_right.png");
    background-size: cover;
    background-origin: border-box;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25%;
  }
}

@media (max-width: 1200px) {
  .detail_div {
    margin: 15rem auto;
    width: 90%;
    .detail_title {
      font-size: 4rem;
    }
    .detail_type{
      font-size: 2.4rem;
    }
    .detail_time{
      font-size: 2.4rem;
    }
  }
  .bottom_div {
    .right_div {
      display: none;
    }
  }
}
</style>