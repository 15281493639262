import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Index from '@/components/Index'
import About from '@/components/About'
import Products from '@/components/Products'
import ProductDetail from '@/components/ProductDetail'
import News from '@/components/News'
import NewsDetail from '@/components/NewsDetail'
import CallUs from '@/components/CallUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '宁波禾采医疗器械有限公司',
    component: Index,
    children: [{
      path: '/',
      component: Home,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },

    },
    {
      path: '/About',
      component: About,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    }, {
      path: '/Products',
      component: Products,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    },
      , {
      path: '/ProductsDetail/:id',
      component: ProductDetail,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    },
      , {
      path: '/News',
      component: News,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    },
      , {
      path: '/NewsDetail/:id',
      component: NewsDetail,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    },
      , {
      path: '/CallUs',
      component: CallUs,
      name: '',
      meta: { title: '宁波禾采医疗器械有限公司', titleEn: 'Ningbo Hecai Medical Equipment Co., Ltd.' },
    },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
